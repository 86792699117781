import io from 'socket.io-client'

class SocketInstance {
  constructor (params) {
    this.baseUrl = params.baseUrl
    this.prefix = params.prefix
    this.accessToken = params.accessToken
    this.setCallbacks(params)
    this.initSocket()
  }

  setAccessToken (accessToken) {
    this.accessToken = accessToken
  }

  emit (url, config, callback, prefix = undefined) {
    prefix = prefix || this.prefix
    this.initSocket(() => {
      this.socket.emit(prefix + url, config, callback)
    })
  }

  setCallbacks ({ onDisconnect, onAuthenticated, onUnAuthorized, subscriptions = undefined }) {
    if (!this.subscriptions) {
      this.subscriptions = {}
    }
    if (subscriptions) {
      for (const subscription in subscriptions) {
        if (subscriptions.hasOwnProperty(subscription)) {
          if (!this.subscriptions[subscription]) {
            this.subscriptions[subscription] = []
          }
          this.subscriptions[subscription].push(subscriptions[subscription])
        }
      }
    }
    if (!this.onCustomAuthenticated) {
      this.onCustomAuthenticated = []
    }
    if (onAuthenticated) {
      this.onCustomAuthenticated.push(onAuthenticated)
    }
    if (!this.onCustomUnAuthorized) {
      this.onCustomUnAuthorized = []
    }
    if (onUnAuthorized) {
      this.onCustomUnAuthorized.push(onUnAuthorized)
    }
    if (!this.onCustomDisconnect) {
      this.onCustomDisconnect = []
    }
    if (onDisconnect) {
      this.onCustomDisconnect.push(onDisconnect)
    }
  }

  onSocketAuthenticated (param) {
    for (const funcName in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(funcName)) {
        if (['authenticated', 'connect', 'unauthorized', 'disconnect'].indexOf(funcName) === -1) {
          if (this.subscriptions[funcName].length) {
            this.subscriptions[funcName].forEach((funcNameItem) => {
              this.socket.off(this.prefix + funcName, funcNameItem)
              console.log('subscribed to ', funcName)
              this.socket.on(this.prefix + funcName, funcNameItem)
            })
          } else {
            this.socket.off(this.prefix + funcName, this.subscriptions[funcName])
            this.socket.on(this.prefix + funcName, this.subscriptions[funcName])
          }
        }
      }
    }
    if (this.onCustomAuthenticated) {
      this.onCustomAuthenticated.forEach((callback) => {
        callback(param)
      })
    }
    console.log('socket authenticated with subscription', this.subscriptions)
  }

  onDisconnect (param) {
    if (!this.subscriptions) {
      return
    }
    if (this.subscriptions.connect) {
      this.socket.off('connect', this.subscriptions.connect)
      this.subscriptions.connect = null
    }
    if (this.subscriptions.authenticated) {
      this.socket.off('authenticated', this.subscriptions.authenticated)
      this.subscriptions.authenticated = null
    }
    for (const funcName in this.subscriptions) {
      if (['authenticated', 'connect', 'unauthorized', 'disconnect'].indexOf(funcName) === -1) {
        if (this.subscriptions[funcName].length) {
          this.subscriptions[funcName].forEach((funcNameItem) => {
            this.socket.off(this.prefix + funcName, funcNameItem)
          })
        } else {
          this.socket.off(this.prefix + funcName, this.subscriptions[funcName])
        }
      }
    }
    if (this.subscriptions.disconnect) {
      this.socket.off('disconnect', this.subscriptions.disconnect)
      this.subscriptions.disconnect = null
    }
    if (this.subscriptions.unauthorized) {
      this.socket.off('unauthorized', this.subscriptions.unauthorized)
      this.subscriptions.unauthorized = null
    }
    this.socket = null
    if (this.onCustomDisconnect) {
      this.onCustomDisconnect.forEach((callback) => {
        callback(param)
      })
    }
    console.log('socket disconnected')
  }

  onUnAuthorized (err) {
    console.log('socket unauthorized')
    if (this.onCustomUnAuthorized) {
      this.onCustomUnAuthorized.forEach((callback) => {
        callback(err)
      })
    }
  }

  initSocket (emitCallback) {
    if (!this.socket || !this.socket.connected) {
      this.socket = io(this.baseUrl)
      this.connect(emitCallback)
      this.subscriptions.disconnect = this.onDisconnect.bind(this)
      this.socket.on('disconnect', this.subscriptions.disconnect)
    } else if (emitCallback) {
      emitCallback()
    }
  }

  connect (authenticatedCallback) {
    if (this.subscriptions.connect) {
      this.socket.off('connect', this.subscriptions.connect)
    }
    this.subscriptions.connect = () => {
      if (this.accessToken) {
        console.log('subscriptions in connect', this.subscriptions)
        if (this.subscriptions.authenticated) {
          this.socket.off('authenticated', this.subscriptions.authenticated)
        }
        if (authenticatedCallback) {
          if (!this.onCustomAuthenticated) {
            this.onCustomAuthenticated = []
          }
          this.onCustomAuthenticated.push(authenticatedCallback)
        }
        this.subscriptions.authenticated = this.onSocketAuthenticated.bind(this)
        this.socket.on('authenticated', this.subscriptions.authenticated)
        this.socket.emit('authentication', { accessToken: this.accessToken })
        console.log('connected')
      } else {
        throw new Error('No access token provided for socket')
      }
    }
    if (this.subscriptions.unauthorized) {
      this.socket.off('unauthorized', this.subscriptions.unauthorized)
    }
    this.subscriptions.unauthorized = this.onUnAuthorized
    this.socket.on('unauthorized', this.subscriptions.unauthorized)
    this.socket.on('connect', this.subscriptions.connect)
  }

  disconnect () {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}

export default SocketInstance
